<template>
    <vue-final-modal :modal-id="modalId" classes="modal-container" content-class="modal-content">
        <div
            class="modal show d-block"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmar Documento</h1>
                        <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            @click="vfm.close(modalId)"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <p v-if="files.length === 0">
                            Esta a punto de finalizar la edicion del documento, y no hay archivos
                            selecionados para enviar
                        </p>
                        <p v-if="files.length === 1">
                            Esta a punto de finalizar la edicion del documento, solo queremos
                            confirmar que este es el archivo que realmente deseas compartir:
                        </p>
                        <p v-if="files.length > 1">
                            Esta a punto de finalizar la edicion del documento, actualmente hay mas
                            de un archivo selecionado, por favor verifique los archivos
                            selecionados.
                        </p>
                        <ul v-if="files?.length">
                            <li v-for="(f, i) in files" :key="f._id">
                                {{ i + 1 }}. {{ f.file.title }}
                            </li>
                        </ul>

                        <p class="fw-bolder" v-if="files.length > 1">
                            <br />
                            Recuerde que puede combinar todos los archivos para enviar uno solo en
                            la tarjeta Combinar Archivos.
                        </p>
                        <p v-if="files?.length === 0" class="text-danger">
                            No Hay Documentos Seleccionados
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning" @click="vfm.close(modalId)">
                            Volver
                        </button>
                        <button
                            v-if="files.length >= 1"
                            type="button"
                            class="btn btn-success"
                            @click="
                                () => {
                                    $emit('onConfirm'), vfm.close(modalId);
                                }
                            "
                        >
                            Finalizar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>
import { VueFinalModal, useVfm } from 'vue-final-modal';

export default {
    name: 'FinalConfirmModal',
    components: {
        VueFinalModal,
    },
    props: {
        modalId: [String, Symbol],
        files: { type: Array, default: () => [] },
    },
    setup() {
        const vfm = useVfm();

        return { vfm };
    },
};
</script>
<style lang="scss" scoped></style>
