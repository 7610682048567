<template>
    <div class="darmin card">
        <div
            role="button"
            class="card-header pb-0 collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="!disabled"
            :aria-controls="`card-${ramId}`"
        >
            <div class="d-flex align-items-center justify-content-between">
                <h5>
                    Combinar Archivos
                    <!-- <span v-if="fileList.length > 1" class="fw-bold text-danger">!</span> -->
                </h5>
                <i class="fas fa-angle-up"></i>
            </div>

            <span>Generar un archivo PDF a partir de varios archivos</span>
        </div>
        <div class="card-body theme-form">
            <div class="collapse" :id="`card-${ramId}`" ref="collapse">
                <div class="row" v-if="fileList.length">
                    <div class="files">
                        <VueDraggableNext
                            class="dragArea row w-full"
                            :list="fileList"
                            @change="log"
                        >
                            <div
                                class="col-md-6 col-lg-4 col-xl-3 align-items-center mb-1 p-3"
                                v-for="file in fileList"
                                :key="'merge_' + file.id"
                            >
                                <ResultadoFile
                                    v-if="showPdfs"
                                    v-model="filesSelected"
                                    :file="file"
                                    :value="file.file.id"
                                    :readOnly="false"
                                />
                            </div>
                        </VueDraggableNext>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-end">
                        <div class="btn btn-warning ms-3 mb-1" @click="handleOnMerged">
                            <i class="far fa-save"></i>
                            Generar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, defineComponent, onMounted, ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import ResultadoFile from './ResultadoFile.vue';

export default defineComponent({
    name: 'MergeFiles',
    components: { VueDraggableNext, ResultadoFile },
    props: {
        disabled: Boolean,
        files: Array,
    },
    setup(props, { emit }) {
        const ramId = ref(Math.floor(Math.random() * 1000));
        const collapse = ref();

        const fileList = computed({
            get() {
                return props.files;
            },
            set(value) {
                emit('update:fileList', value);
            },
        });
        const filesSelected = ref([]);

        const handleOnMerged = () => {
            emit('onMergeFiles', { files: filesSelected.value });
            filesSelected.value = [];
        };

        const log = () => {
            emit('update:fileList');
            // emit('onUpdate');
        };

        const getUrl = (id) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            `${process.env.VUE_APP_MAIN_SERVICE}/care/examen/resultado/files/view/${id}`;

        const showPdfs = ref(false);
        onMounted(() => {
            collapse.value.addEventListener('show.bs.collapse', () => {
                // do something...
                console.log('shown.bs.collapse');
                showPdfs.value = true;
            });
            collapse.value.addEventListener('hidden.bs.collapse', () => {
                // do something...
                console.log('hidden.bs.collapse');
                showPdfs.value = false;
            });
        });

        return {
            ramId,
            fileList,
            log,
            getUrl,
            filesSelected,
            handleOnMerged,
            showPdfs,
            collapse,
        };
    },
});
</script>

<style scoped>
.col-form-label {
    text-transform: capitalize;
}

.files .file {
    /* cursor: pointer; */
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
}
.files .file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
.files .title {
    margin: 0;
}
.files i {
    cursor: pointer;
}
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
