<template>
    <div class="darmin card">
        <div
            role="button"
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Informacion del resultado</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <div class="default-according">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <template v-for="(eD, i) in template?.data" :key="i">
                                    <div :class="getColSize(eD.type)" v-if="eD.auto !== 'true'">
                                        <div class="mb-3">
                                            <label class="col-form-label">{{ eD.name }}</label>
                                            <input
                                                v-if="eD.type == 'String'"
                                                class="form-control"
                                                type="text"
                                                :value="info[eD.name]"
                                                readonly
                                            />
                                            <input
                                                v-if="eD.type == 'Number'"
                                                class="form-control"
                                                type="number"
                                                :value="info[eD.name]"
                                                readonly
                                            />
                                            <select
                                                v-if="eD.type == 'marca_x'"
                                                class="form-select digits"
                                                :value="info[eD.name]"
                                                disabled="true"
                                                readonly
                                            >
                                                <option value="true">Seleccionado</option>
                                                <option value="false">Vacio</option>
                                            </select>
                                            <input
                                                v-if="eD.type == 'Date'"
                                                class="form-control"
                                                type="text"
                                                :value="info[eD.name]"
                                                readonly
                                            />
                                            <input
                                                v-if="eD.type == 'Age'"
                                                class="form-control"
                                                type="text"
                                                :value="info[eD.name]"
                                                readonly
                                            />
                                            <textarea
                                                v-if="eD.type == 'Paragrahp'"
                                                class="form-control"
                                                :value="info[eD.name]"
                                                readonly
                                                rows="4"
                                            ></textarea>
                                            <textarea
                                                v-if="eD.type == 'Paragrahp_optional'"
                                                class="form-control"
                                                :value="info[eD.name]"
                                                readonly
                                                rows="4"
                                            ></textarea>
                                            <textarea
                                                v-if="eD.type == 'List'"
                                                class="form-control"
                                                :value="info[eD.name]"
                                                readonly
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'DataRendered',
    props: {
        info: {
            type: Object,
            required: true,
        },
        template: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const ramId = ref(Math.floor(Math.random() * 1000));

        const getColSize = (type) => {
            switch (type) {
                case 'List':
                case 'Paragrahp':
                case 'Paragrahp_optional':
                    return 'col-lg-12';
                default:
                    return 'col-lg-4';
            }
        };

        const log = () => {
            emit('update:fileList');
            // emit('onUpdate');
        };

        return {
            log,
            ramId,
            getColSize,
            env: process.env.NODE_ENV,
        };
    },
});
</script>

<style scoped>
.col-form-label {
    text-transform: capitalize;
}

.files .file {
    /* cursor: pointer; */
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
}
.files .file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
.files .title {
    margin: 0;
}
.files i {
    cursor: pointer;
}
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
